

































































import { Component, Mixins } from 'vue-property-decorator';
import { View } from 'client-website-ts-library/plugins';
import { API, Config } from 'client-website-ts-library/services';
import { Office } from 'client-website-ts-library/types';
import WelcomeModal from '../components/WelcomeModal.vue';

@Component({
  components: {
    WelcomeModal,
  },
})
export default class CompleteGuideToSellingAProperty extends Mixins(View as any) {
  public office: Office | null = null;

  public modalOpen = false;

  public modalContent = '';

  mounted() {
    API.Offices.Get(Config.Website.Settings!.WebsiteId).then((office) => {
      this.office = office;
    });
  }

  openModal(pdf: string): void {
    this.modalContent = pdf;
    this.modalOpen = true;
  }
}
